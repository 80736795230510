<template>
    <svg class="fill-deep-blue" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px"
         viewBox="0 0 343.49 53.63" style="enable-background:new 0 0 343.49 53.63;" xml:space="preserve">
        <path class="st0" d="M70.23,50.44v3.19H53.31L48.2,41.5H22.03l-5.11,12.13H0v-3.19h8.62L30.33,0.96h9.58l21.71,49.48H70.23z
	 M44.37,33.52L34.8,11.81l-9.58,21.71H44.37z"/>
        <path class="st0"
              d="M70.23,50.44V0.96h8.94l30.65,39.27V0.96h8.94v49.48h7.34v3.19h-16.92L79.49,15.32v38.31H63.21v-3.19H70.23z"/>
        <path class="st0" d="M144.61,50.44V32.88L125.14,0.96h9.58l14.37,23.94l14.37-23.94h9.58l-19.47,31.92v17.56h18.83v3.19h-46.93
	v-3.19H144.61z"/>
        <path class="st0" d="M180.05,50.44V0.96h8.94V26.5l23.94-25.54h11.17l-21.07,22.98l19.15,26.5l0,0h7.34v3.19h-15.64l-17.56-22.98
	l-7.66,8.3v14.68h-16.28v-3.19H180.05z"/>
        <path class="st0" d="M288.9,50.44v3.19H225.7v-3.19h17.24c-4.15-2.23-7.34-5.75-9.58-9.58c-2.55-4.15-3.51-8.62-3.51-13.73
	c0-7.66,2.55-14.05,7.98-19.47C243.25,2.23,249.64,0,257.62,0c7.98,0,14.37,2.55,19.79,7.66c5.43,5.11,7.98,11.49,7.98,19.47
	c0,5.11-1.28,9.58-3.51,13.73s-5.75,7.34-9.58,9.58H288.9z M270.39,13.41c-3.51-3.83-7.98-5.43-13.09-5.43
	c-5.11,0-9.58,1.92-13.09,5.43c-3.51,3.83-5.43,8.3-5.43,13.41s1.92,9.9,5.43,13.41c3.51,3.83,7.98,5.43,13.09,5.43
	c5.11,0,9.58-1.92,13.09-5.43c3.51-3.83,5.43-8.3,5.43-13.41C275.81,21.71,273.9,17.24,270.39,13.41z"/>
        <path class="st0" d="M326.25,50.76c8.94-4.15,13.09-11.81,13.09-22.98c0-8.3-2.55-14.68-7.66-19.47
	c-5.11-4.79-12.45-7.02-21.71-7.02h-18.2v15.96h8.94V9.26h8.94c13.73,0,20.75,6.07,20.75,18.2c0,6.07-1.6,10.53-5.11,13.41
	c-3.51,3.19-8.3,4.47-14.68,4.47h-9.9v-9.58h-8.94v14.68h-7.02v3.19h58.74v-3.19h-17.24V50.76z"/>
        <polygon class="st0" points="304.23,24.26 302.31,21.07 297.84,24.26 298.16,18.52 294.65,18.52 294.97,24.26 290.5,21.07
	288.58,24.26 293.69,26.82 288.58,29.05 290.5,32.24 294.97,29.05 294.65,34.48 298.16,34.48 297.84,29.05 302.31,32.24
	304.23,29.05 299.12,26.82 "/>
        <polygon class="st0" points="265.12,24.26 263.2,21.07 258.74,24.26 259.05,18.52 255.54,18.52 255.86,24.26 251.39,21.07
	249.48,24.26 254.59,26.82 249.48,29.05 251.39,32.24 255.86,29.05 255.54,34.48 259.05,34.48 258.74,29.05 263.2,32.24
	265.12,29.05 260.01,26.82 "/>
        <polygon class="st0" points="226.81,24.26 224.9,21.07 220.43,24.26 220.75,18.52 217.24,18.52 217.55,24.26 213.09,21.07
	211.17,24.26 216.28,26.82 211.17,29.05 213.09,32.24 217.55,29.05 217.24,34.48 220.75,34.48 220.43,29.05 224.9,32.24
	226.81,29.05 221.7,26.82 "/>
</svg>

</template>

<style scoped>

</style>
